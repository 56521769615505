import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import Container from "components/container"
import Layout from "components/layout"
import colors from "theme/colors"
import ResourcePageHeader from "components/ResourcePageHeader"
import DownloadButton from "components/DownloadButton"
import P from "components/typography/P"
import H3 from "components/typography/H3"
import Button from "components/Button"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import fluidValues from "utilities/fluidValues"

const NowhereFast = ({ path, pageContext, data, rules, styles }) => {
  const { title, meta, slug, color, intro, comics, headerimage, combo } =
    data.sanityComicsPage

  const pageColor = colors[`color${color || "Human"}100`]
  const linkColor = colors[`color${color || "Human"}500`]

  return (
    <Layout
      pageColor={pageColor}
      linkColor={linkColor}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      <ResourcePageHeader
        title={title}
        intro={intro}
        pageColor={pageColor}
        extend={{ introCircle: rules.circle, intro: rules.headerIntro }}
        annoying
        headerImage={headerimage}
      />

      <Container
        flex
        noPadding
        withContentArea
        extend={{ styles: rules.postersContainer }}
      >
        {comics &&
          comics
            .filter(c => c.image)
            .map((comic, index) => (
              <div key={index} className={styles.comicWrapper}>
                <div className={styles.topContainer}>
                  <div className={styles.paragraphTitle}>
                    <H3 noMargin>{comic.title}</H3>
                  </div>
                </div>
                <Img
                  className={styles.image}
                  image={comic.image.asset.gatsbyImageData}
                  alt={""}
                />
                <div className={styles.bottomContainer}>
                  <P version={"info"} extend={{ styles: rules.excerpt }}>
                    {comic.excerpt}
                  </P>
                </div>
                <div className={styles.downloadButtonsContainer}>
                  {comic.image && (
                    <div className={styles.downloadButtonContainer}>
                      <DownloadButton
                        target="_blank"
                        download={comic.image.asset.originalFilename}
                        extend={{ styles: rules.downloadButton }}
                        to={
                          comic.image.asset.url +
                          "?filename=" +
                          comic.image.asset.originalFilename.replace(
                            / /g,
                            "_"
                          ) +
                          "&dl=" +
                          comic.image.asset.originalFilename.replace(/ /g, "_")
                        }
                      />
                      <P
                        version="info"
                        extend={{ styles: rules.downloadLabel }}
                      >
                        DOWNLOAD CARTOON
                      </P>
                    </div>
                  )}
                  {comic.zoomBg && (
                    <div className={styles.downloadButtonContainer}>
                      <DownloadButton
                        target="_blank"
                        download={comic.zoomBg.asset.originalFilename}
                        extend={{ styles: rules.downloadButton }}
                        to={
                          comic.zoomBg.asset.url +
                          "?filename=" +
                          comic.zoomBg.asset.originalFilename.replace(
                            / /g,
                            "_"
                          ) +
                          "&dl=" +
                          comic.zoomBg.asset.originalFilename.replace(/ /g, "_")
                        }
                      />
                      <P
                        version="info"
                        extend={{ styles: rules.downloadLabel }}
                      >
                        DOWNLOAD ZOOM BACKGROUND
                      </P>
                    </div>
                  )}
                </div>
              </div>
            ))}

        {combo && (
          <div className={styles.comicWrapper}>
            <div className={styles.topContainer}>
              <div className={styles.paragraphTitle}>
                <H3 noMargin>{combo.title}</H3>
              </div>
            </div>
            {combo.image && (
              <Img
                className={styles.image}
                image={combo.image.asset.gatsbyImageData}
                alt={""}
              />
            )}
            <div className={styles.bottomContainer}>
              <P version={"info"} extend={{ styles: rules.excerpt }}>
                {combo.excerpt}
              </P>
            </div>
            <div className={styles.AllDownloadButtonsContainer}>
              {combo.archive?.asset && (
                <div className={styles.AllDownloadButtonContainer}>
                  <Button
                    download={combo.archive.asset.originalFilename}
                    extend={{ styles: rules.AllDownloadButton }}
                    to={combo.archive.asset.url}
                  >
                    DOWNLOAD ALL
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </Layout>
  )
}

const styles = () => ({
  circle: {
    borderRadius: 0,
    phoneOnly: {
      width: "100vw",
    },
  },
  headerIntro: {
    maxWidth: 400,
  },
  postersContainer: {
    zIndex: 2,
    ...fluidValues({
      marginBottom: [38, 60],
    }),
    marginTop: -20,
  },
  comicWrapper: {
    width: "100%",
    ...fluidValues({
      marginBottom: [90, 218],
    }),
  },
  image: {
    overflow: "visible !important",
    onAfter: {
      content: '""',
      display: "block",
      backgroundColor: colors.colorConsole500,
      position: "absolute",
      width: "95%",
      left: "calc(50% - (95% / 2))",
      right: "auto",
      ...fluidValues({
        bottom: [-6, -12],
        height: [6, 12],
      }),
    },
  },
  topContainer: {
    ...fluidValues({
      marginLeft: [64, 187],
    }),
    display: "flex",
    alignItem: "baseline",
    paddingRight: 20,
  },
  bottomContainer: {
    display: "flex",
    paddingRight: 20,
    borderLeft: "1px solid " + colors.colorConsole500,
    ...fluidValues({
      paddingTop: [36, 42],
      marginLeft: [43, 77],
      paddingLeft: [24, 33],
    }),
  },
  paragraphTitle: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    ...fluidValues({
      marginLeft: [-21, -110],
      paddingBottom: [12, 32],
      paddingLeft: [24, 33],
      paddingTop: [9, 27],
    }),
    borderLeft: "1px solid " + colors.colorConsole500,
  },
  downloadButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    paddingTop: 24,
    ...fluidValues({
      marginLeft: [43, 77],
      paddingLeft: [24, 33],
    }),
    tabletPortraitAndUp: {
      flexDirection: "row",
      alignItems: "end",
    },
    phoneOnly: {
      borderLeft: "1px solid " + colors.colorConsole500,
    },
  },
  downloadButtonContainer: {
    display: "flex",
    alignItems: "end",
    marginBottom: 6,
  },
  downloadButton: {
    flex: "0 1 auto",
    display: "block",
    ...fluidValues({
      width: [36, 60],
      height: [36, 60],
    }),
  },
  downloadLabel: {
    fontWeight: 400,
    marginLeft: 12,
    marginRight: 32,
  },
  AllDownloadButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    paddingTop: 24,
    tabletPortraitAndUp: {
      flexDirection: "row",
      alignItems: "end",
    },
    phoneOnly: {
      borderLeft: "1px solid " + colors.colorConsole500,
    },
  },
  AllDownloadButtonContainer: {
    ...fluidValues({
      marginBottom: [90, 200],
    }),
    marginLeft: "auto",
    marginRight: "auto",
  },
  AllDownloadButton: {
    position: "relative",
    margin: "0 auto",
    display: "block",
    ...fluidValues({
      width: [243, 243],
    }),
  },
})

NowhereFast.defaultProps = {
  rules: {},
  styles: {},
}

NowhereFast.propTypes = {
  data: PropTypes.object.isRequired,
  rules: PropTypes.object,
  styles: PropTypes.object,
}

export default connectFela(styles)(NowhereFast)

export const query = graphql`
  query ($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
      ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
      edges {
        node {
          ...Jobs
        }
      }
    }

    sanityComicsPage {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      headerimage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1440)
        }
      }
      color
      intro
      combo {
        title
        excerpt
        image {
          asset {
            url
            originalFilename
            gatsbyImageData(layout: CONSTRAINED, width: 1686)
          }
        }
        archive {
          asset {
            url
            originalFilename
          }
        }
      }
      comics {
        title
        excerpt
        image {
          asset {
            url
            originalFilename
            gatsbyImageData(layout: CONSTRAINED, width: 1686)
          }
        }
        zoomBg {
          asset {
            url
            originalFilename
            gatsbyImageData(layout: CONSTRAINED, width: 1686)
          }
        }
      }
    }
  }
`
